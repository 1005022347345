import * as React from 'react';
import staffingServices from "../services/staffingServices";
import {useSelector} from "react-redux";
import LearningService from 'src/services/learningService';
import history from 'src/utils/history';

export const StaffingContext = React.createContext({});

const emailArray = [
  "rkulkarni@medtigo.com",
  "rlangenback@medtigo.com",
  "ashahi@medtigo.com",
  "rswami@medtigo.com",
  "gkshirsagar@medtigo.com",
  "nkindre@medtigo.com",
  "sgavali@medtigo.com",
  "bbaneraj@medtigo.com",
  "ashrawane@medtigo.com",
  "daniel.clark45@yahoo.com",
  "james.anderson@example.com",
  "brian.moore12@aol.com"
];

export const StaffingContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState(0);
  const [tabrecord,setTabrecord]=React.useState(activeTab)
  const [jobsData, setJobsData] = React.useState([]);
  const [jobsDataUpdate,setJobsDataUpdate]=React.useState(true)
  const [bookData,setBookData]=React.useState([])
  const [bookDataRender,setBookDataRender]=React.useState(true)
  const user = useSelector((state) => state.account.user);
  const [isLoading, setIsLoading] = React.useState(false);
  const [intendeData,setIntendedData]=React.useState([])

  const [adminViewAccess, setAdminViewAccess] = React.useState(false); // Set Access to Admin View based on user role
  const [showAdminView, setShowAdminView] = React.useState(false); // Show Admin View based on Admin View Toggle

  // Change Admin View Toggle
  const toggleAdminView = () => { 
    setShowAdminView(prev => {
      const newValue = !prev;
      if(newValue){
        history.push('/admin/dashboard')
      } else {
        history.push('/dashboard')
      }
      return newValue;
    });
  };

  // Set Admin View Access based on user role 
  React.useEffect(() => {
    if (emailArray.includes(user?.email)) {
      setAdminViewAccess(true);
    }
  }, []);

  // Get Admin View Toggle from Local Storage on Page Load
  React.useEffect(() => {
    if (window.location.pathname.includes('/admin')) {
      setShowAdminView(true);
    }
  }, []);

  const fetchJobs = async () => {
    setIsLoading(true);
    try {
      const jobsData = await staffingServices.getJobs();
      setJobsData(jobsData.Data);
    } catch (error) {
      console.log(error);
      setJobsData([]);
    }
    setIsLoading(false);
  };

  const intendedData=async ()=>{
try{
  const intendedData= await staffingServices.geIntendedJobs()
  setIntendedData(intendedData.data.data)

   setTimeout(()=>{

      setActiveTab(1)
    
  },0)
  setTimeout(()=>{
    setActiveTab(0)
    },0)

 
}catch(error){
console.log(error)
}
  }
  const saveData=async ()=>{
try{
  const bookmarkData= await staffingServices.getBookmarkJob()
  setBookData(bookmarkData.data.data)
}catch (error){
console.log(error)
}
  }
  

  React.useEffect(() => {
    user && fetchJobs()
   && intendedData()&&
    saveData()
  }, [user,jobsDataUpdate,bookDataRender]);



  const defaultContext = {
    activeTab,
    setActiveTab,
    isLoading,
    setJobsDataUpdate,
    jobsData,
    jobsDataUpdate,
    intendeData,
    bookData,
    bookDataRender,
    setBookDataRender,
    adminViewAccess,
    showAdminView,
    setShowAdminView,
    toggleAdminView
  };

  return (
    <StaffingContext.Provider
      value={defaultContext}
    >
      {children}
    </StaffingContext.Provider>
  );
};