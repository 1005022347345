import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useOpenSnackbar } from 'src/hooks/useOpenSnackbar';

const emailArray = [
  "rkulkarni@medtigo.com",
  "rlangenback@medtigo.com",
  "ashahi@medtigo.com",
  "rswami@medtigo.com",
  "gkshirsagar@medtigo.com",
  "nkindre@medtigo.com",
  "sgavali@medtigo.com",
  "bbaneraj@medtigo.com",
  "ashrawane@medtigo.com",
  "daniel.clark45@yahoo.com",
  "james.anderson@example.com",
  "brian.moore12@aol.com"
];

function AdminGuard({ children }) {
  const user = useSelector((state) => state.account.user);
  const openSnackbar = useOpenSnackbar();
  const allowAccess = emailArray.includes(user.email);

  if (!allowAccess) {
    openSnackbar('Unauthorised Access!', 'error');
    return <Redirect to="/login" />;
  }

  return children;
}

AdminGuard.propTypes = {
  children: PropTypes.any
};

export default AdminGuard;
