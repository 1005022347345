import React, { createContext, useContext, useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LearningService from 'src/services/learningService';
import orderServices from 'src/services/orderServices';
import { useBuyPlan } from 'src/hooks/useBuyPlan';
import moment from 'moment';
import { LearningContext } from 'src/context/LearningContext';

export const MyLearningDataContext = createContext();

const calculateExpiryDate = (createdAt, validity) => {
  const validityInMonths = validity * 12;
  const expiryDate = moment(createdAt)
    .add(validityInMonths, 'months')
    .subtract(1, 'day');
  return expiryDate;
};

// Object to store purchased plan( basic / standard / best_value ) for each course
const coursePlans = {
  4526: '', // ACLS
  9985: '', // BLS
  9238: '', // PALS
  79132: '', // NRP
  151904: '', // ASLS
  // 192797: '', // NIHSS
  11159: '', // OPIOID
};

// Object to store course tile text & course progress
const coursesDetails = {
  4526: { name: 'ACLS', status: 'EXPLORE PLANS', progress: 0 },
  9985: { name: 'BLS', status: 'EXPLORE PLANS', progress: 0 },
  9238: { name: 'PALS', status: 'EXPLORE PLANS', progress: 0 },
  79132: { name: 'NRP', status: 'EXPLORE PLANS', progress: 0 },
  151904: { name: 'ASLS', status: 'EXPLORE PLANS', progress: 0 },
  11159: { name: 'OPIOID', status: 'FREE COURSE', progress: 0 },
};

export const MyLearningDataProvider = ({ children }) => {
  const history = useHistory();

  const [dialogs, setDialogs] = useState({ explore: false, upgrade: false, retake: false, certificate: false}); // open respective dialog boxes
  const [pageLoading, setPageLoading] = useState(true); // load the page till all api calls are fulfilled
  const [courseData, setCourseData] = useState({}); // Store data for course when button is clicked -> Further used for dialog boxes
  const [userCourseProgress, setUserCourseProgress] = useState([]); // Store progress summary for all coursess
  const [userOrderDetails, setUserOrderDetails] = useState([]); // Store all orders placed by user in reverse order
  const [currentCoursePlans, setCurrentCoursePlans] = useState(coursePlans); // Store course plan for each course

    // Store current status & progress for each course
    const [courseDetails, setCourseDetails] = useState(coursesDetails);
  
    // Method to update status or progress for a specific course
    const updateCourseDetails = (courseId, newStatus, newProgress) => {
      setCourseDetails(prevState => ({
        ...prevState,
        [courseId]: {
          ...prevState[courseId],
          ...(newStatus !== null && { status: newStatus }),  // Update status if provided
          ...(newProgress !== null && { progress: newProgress }) // Update progress if provided
        }
      }));
    };

  const [refresh, setRefresh] = React.useState(0);

  const { buyPlan, isLoading, setIsLoading } = useBuyPlan();
  const { email } = useSelector((state) => state.account.user || { email: '' });
  const { userSubscription } = useContext(LearningContext);

  const isTeamHealthUser = email.includes('@teamhealth.com');

  // Open Dialog Box based on type & selected course
  const handleOpenDialog = useCallback((type, course) => {
    setCourseData(course);
    setDialogs(prev => ({ ...prev, [type]: true }));
  }, []);

  const handleCloseDialog = type => {
    setDialogs(prev => ({ ...prev, [type]: false }));
  };

  // Redirect to medtigo store adding selected course plan to cart
  const handleBuyPlan = useCallback(() => {
    setIsLoading(true);
    buyPlan();
  }, [buyPlan, setIsLoading]);

  // Perform action based on button text for selected course
  const handleButtonClick = useCallback((buttonText, course) => {
    // New Renew flow for TeamHealth User - Same as Retake Flow
    if (['EXPLORE PLANS', 'RENEW'].includes(buttonText) && isTeamHealthUser) {
      handleOpenDialog('retake', course);
    } else if (['EXPLORE PLANS', 'RENEW'].includes(buttonText) && course.id == 192797) {
      buyPlan(86144);
    } else if (['EXPLORE PLANS', 'RENEW'].includes(buttonText)) {
      handleOpenDialog('explore', course);
    } else if (buttonText === 'UPGRADE') {
      handleOpenDialog('upgrade', course);
    } else if (buttonText === 'FREE COURSE' && course.id === 11159) {
      buyPlan(6025);
    } else {
      localStorage.setItem("learning-path", window.location.pathname);
      window.location.href = `/learning/course/${course.id}`
    }
  }, [handleOpenDialog, isTeamHealthUser]);

    // Fetch User Purchase Order Details, Course Progress, Certificates
  const fetchData = useCallback(async () => {
    try {
      setPageLoading(true);

      // Fetching user course progress
      const progress = await LearningService.getUserProgresses(email);
      setUserCourseProgress(progress.summary);

      // Fetching user order details
      const userOrderDetails = await orderServices.getOrderItemsDetailsForCourses(email);
      setUserOrderDetails(userOrderDetails.reverse());
    } catch (error) {
      console.error('Error fetching data: ', error);
    } finally {
      setPageLoading(false);
    }
  }, [email]);

  useEffect(() => {
    fetchData();
  }, [refresh]);

  // Check whether user has Full Access Plan & is still valid/ not expired
  const fullAccess = useMemo(() => {
    let hasFullAccess = false;
    if (isTeamHealthUser) {
      hasFullAccess = true; // Free Full Access for TeamHealth User
    } else if (userSubscription.length > 0) {
      userSubscription.forEach(subscription => {
        if (subscription.subscriptionType === 'medtigo_full_access') {
          const expiry = calculateExpiryDate(subscription.endDate, 2);
          if (!expiry.isBefore(moment(), 'day')) {
            hasFullAccess = true;
          }
        }
      });
    }
    return hasFullAccess;
  }, [userSubscription, isTeamHealthUser]);

  return (
    <MyLearningDataContext.Provider
      value={{ setRefresh, dialogs, pageLoading, courseData, userCourseProgress, isTeamHealthUser, userOrderDetails, currentCoursePlans, fullAccess, 
        courseDetails, updateCourseDetails, isLoading, setCurrentCoursePlans, handleOpenDialog, handleCloseDialog, handleButtonClick, handleBuyPlan
      }}
    >
      {children}
    </MyLearningDataContext.Provider>
  );
};
